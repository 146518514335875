import styled from '@emotion/styled';
import { colors, ImageIcon, CustomCard, Text } from '@innovationdepartment/proxima-ui';
import { PerformanceAdsViewProps } from 'components/PerformanceAds/types';
import PerformanceAdsTilesResultsBar from './PerformanceAdsTiles.ResultsBar';
import InfiniteScroll from 'ui/InfiniteScroll';
import React from 'react';

const TilesWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  height: 100%;
  overflow: auto;
`;

const TilesContentWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
  grid-gap: 16px;
  overflow: auto;
  padding: 0 16px 16px;

  & > * {
    justify-self: center;
  }
`;

const PerformanceAdsTable = (props: PerformanceAdsViewProps) => {
  const { ads, onRequestNext } = props;

  return (
    <TilesWrapper>
      <PerformanceAdsTilesResultsBar {...props} />
      <TilesContentWrapper>
        {ads.data.map((ad, index) => (
          <React.Fragment key={ad.adId}>
            <CustomCard
              key={ad.adId}
              size="tile"
              contentText={
                <div
                  style={{
                    height: 44,
                    overflow: 'hidden',
                    width: '100%',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Text variant="body3">{ad.name}</Text>
                </div>
              }
              label={<Text variant="body1Semibold">{ad.brand?.name}</Text>}
              headerProps={{
                heroUrl:
                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc_8xuBVttgoIei52J4NiD6ve1Q0nB588Fvg&s',
                tag: {
                  label: 'Static',
                  icon: <ImageIcon />,
                },
              }}
            />
            {index === ads.data.length - 1 && onRequestNext && (
              <InfiniteScroll onInfiniteScroll={onRequestNext} />
            )}
          </React.Fragment>
        ))}
      </TilesContentWrapper>
    </TilesWrapper>
  );
};

export default PerformanceAdsTable;
