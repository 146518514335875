import { AllowedColors, Button, colors } from '@innovationdepartment/proxima-ui';
import HorizontalNavbar from 'ui/HorizontalNavbar';
import { PerformanceAdsViewProps } from './types';

const PerformanceAdsNavbar = (props: PerformanceAdsViewProps) => {
  const { onGoBackClick } = props;
  return (
    <HorizontalNavbar label="Creative Perfomance Library">
      <Button
        overrideColors={{
          backgroundColor: colors.neutral100,
          textColor: colors.white as AllowedColors,
        }}
        variant="outlined"
        color="secondary"
        label="Back to Nova"
        onClick={onGoBackClick}
      />
    </HorizontalNavbar>
  );
};

export default PerformanceAdsNavbar;
